@import '../../../../styles/customMediaQueries.css';
.root {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  padding: 40px 20px;
  position: relative;
  @media (--viewportMedium) {
    width: 700px;
    height: 400px;
    padding: 50px;
  }
}

.teaserTitle {
  font-weight: var(--fontWeightBold);
  margin-bottom: 20px;
  font-size: 25px;
  line-height: 32px;
  @media (--viewportMedium) {
  }
}

.teaserDescription {
  margin: 0;
  &:last-child {
    margin-bottom: 30px;
  }
}

.newsLetterForm {
  width: 80%;
  @media (--viewportMedium) {
    width: 70%;
  }
}

.closeModalWrapper {
  position: absolute;
  top: 10px;
  right: 20px;
  display: flex;
  cursor: pointer;
  align-items: center;
  & .close {
    margin-right: 10px;
    font-size: 16px;
  }
}
